<div class="model-login">
  <table class="model-login-table">
    <tbody>
      <tr>
        <td class="login-logo">
          <button class="btn-close login-close" (click)="auth.toggleLoginDisplay(false)">
            <i class="fa-solid fa-xmark"></i>
          </button>
          <img src="../../assets/images/global/logo.png" alt="" />
        </td>
      </tr>
      <tr>
        <td class="model-login-body">
          <div class="body-content">
            <form [formGroup]="frmAuth" (ngSubmit)="enterLogin(1)" class="form-list" novalidate>
              <div class="item">
                <span class="item-icon">
                  <i class="fa fa-user"></i>
                </span>
                <input type="text" name="memberName" class="input i18n-input sm-user" data-tag="username" placeholder="{{
                    'accounts.login.enter_username' | translate
                  }}" minlength="1" maxlength="14" formControlName="username" />
              </div>
              <div class="item">
                <span class="item-icon">
                  <i class="fa fa-lock"></i>
                </span>
                <input type="password" name="pwd" class="input i18n-input sm-password" data-tag="registerPassword"
                  placeholder="{{
                    'accounts.login.enter_password' | translate
                  }}" minlength="6" maxlength="14" formControlName="password" />
              </div>
              <div class="item">
                <span class="item-icon">
                  <i class="fa-brands fa-get-pocket"></i>
                </span>
                <input type="text" name="checkcode" class="input i18n-input sm-piccodenumber" data-tag="picCode"
                  placeholder="piccode" minlength="4" maxlength="4" formControlName="captcha" (focus)="getCodeImg(2)" />
                <span class="checkcode">
                  <!-- Display the validation code image -->
                  <img *ngIf="base64Image" [src]="base64Image" alt="Validation Code" />

                  <!-- Hidden canvas element -->
                  <canvas #codeCanvas style="display: none;"></canvas>

                  <!-- <img src=" " alt="" /> -->
                </span>
              </div>
              <div class="item text-left">
                <div class="checkbox">
                  <input type="checkbox" id="remember" name="remember" formControlName="rememberMe"
                    data-target="#msgLogin" />
                  <label for="remember"></label>
                </div>
                <span class="i18n" data-tag="rememberMe">{{
                  'accounts.login.remember_me' | translate
                  }}</span>
              </div>
              <div class="item">
                <button type="submit" class="btn btn-primary i18n" data-tag="login">
                  {{ 'accounts.login.login' | translate }}
                </button>
              </div>
            </form>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>